/* global FreshworksWidget */
import React, {createContext, lazy, Suspense, useEffect} from 'react'

import {ModalSpinner, NotFound} from '@paytheory/pay-theory-ui'

// import {generateMenu} from './views/util'

import './App.css'

import {Route, Routes} from 'react-router-dom'

import * as ROUTES from './constants/routes'

const Font = lazy(() =>
    import ('@paytheory/pay-theory-ui/dist/Font'))

// const Unauthorized = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

// const NotFound = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

const Invoice = lazy(() =>
    import ('./views/Invoice'))

const UpdateRecurring = lazy(() =>
    import ('./views/UpdateRecurring'))

const PaymentLink = lazy(() =>
    import ('./views/PaymentLink'))

const HostedCheckout = lazy(() =>
    import ('./views/Hosted'))

export const AppContext = createContext()

const App = () => {

    const typekit = process.env.REACT_APP_TYPEKIT

    useEffect(() => {
        FreshworksWidget('hide', 'launcher');
    }, [])

    return (
        <div className="spinner-wrapper">
            <div className="modal-wrapper">
                    <Suspense fallback={<ModalSpinner on/>}>
                        <Routes>
                            <Route
                                exact
                                path={ROUTES.INVOICE}
                                element={<Invoice />}
                            />
                            <Route
                                exact
                                path={ROUTES.UPDATE_RECURRING}
                                element={<UpdateRecurring />}
                            />
                            <Route
                                exact
                                path={ROUTES.PAYMENT_LINK}
                                element={<PaymentLink />}
                            />
                            <Route
                                exact
                                path={ROUTES.HOSTED}
                                element={<HostedCheckout />}
                            />
                            <Route path='*' element={<NotFound />}/>
                        </Routes>
                        <Font typekit={typekit}/>
                    </Suspense>
                    <ModalSpinner/>
            </div>
        </div>
    )
}

export default App
